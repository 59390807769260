.not-liquid-amount{
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 15px;
  height: 40px;


  &__plus{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #000000;
    cursor: pointer;
    width: 20px;
    text-align: center;
    height: 20px;

    &:hover{
      color: #F5B142;
    }
  }
  &__minus{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #000000;
    cursor: pointer;
    width: 20px;
    text-align: center;
    height: 20px;

    &:hover{
      color: #F5B142;
    }
  }
  &__amount{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.05em;
    color: #000000;
    margin: 0px 15px;
  }
}




.not-liquid-product{
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  @include respond-down(small) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__left{
    width: 49%;
    @include respond-down(small) {
      width: 100%;
    }
  }

  &__right{
    width: 49%;
    @include respond-down(small) {
      width: 100%;
    }
  }

  &__slider{
    margin-bottom: 15px;

    img{
      max-height: 370px;
      object-fit: contain;
    }
  }

  &__slider-menu{

    img{
      object-fit: contain;
      height: 145px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__header{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &::after{
      content: '';
      height: 2px;
      max-width: 90%;
      transform: translateX(-50%);
      width: 50px;
      background: #f5b142;
      position: absolute;
      bottom: 0px;
      left: 25px;
    }
  }

  &__actions{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    @include respond-down(small) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: space-around;
      align-items: center;
    }
  }

  &__last{
    @include respond-down(small) {
        margin-bottom: 20px;
    }
  }

  &__price{
    @include respond-down(small) {
      margin-bottom: 20px;
    }
  }


  &__amount{
    @include respond-down(small) {
      margin-bottom: 20px;
    }
  }

  &__button{
    background: #F5B142;
    border-radius: 25px;
    width: 200px;
    padding: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
    height: 40px;

    &:hover{
      background: #001e4d;
      color: #ffffff;
    }
  }

  &__icon-text{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #8886A0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    @include respond-down(small) {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    svg{
      margin-right: 5px;
    }
  }

  &__delimiter{
    width: 100%;

    border-bottom: 2px solid #F5B142;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  &__text{
    margin-top: 30px;

    @include respond-down(small) {
        margin-bottom: 50px;
    }
  }
}