.not-liquid{
  &__header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;

    @include respond-down(small) {
      grid-template-columns: 1fr;
    }

  }
  &__catalog{
    margin-right: 10px;

    @include respond-down(small) {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }

  &__search{
    margin-left: 10px;

    @include respond-down(small) {
      margin-left: 0px;
    }
  }

  &__items{

  }
}