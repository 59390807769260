.not-liquid-product-list-item{
  display: flex;
  margin-bottom: 30px;


  @include respond-down(small) {
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }



  &__image{
    width: 40%;
    border-right: 2px solid #F5B142;
    padding: 30px;
    background-color: #fff;

    @include respond-down(small) {
      width: 100%;
      border: 0px;
    }
  }
  &__img{
    width: 100%;
  }
  &__wrap{
    padding: 30px;
    background-color: #fff;
    width: 100%;
  }
  &__name{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 20px;
  }
  &__text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #8886A0;
    margin-bottom: 20px;
  }
  &__description{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 50px;
  }
  &__date{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #8886A0;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;

    margin-right: 30px;

    @include respond-down(small) {
      margin-bottom: 20px;
      margin-right: 0px;
      width: 100%;
    }

    svg{
      margin-right: 10px;
    }
  }
  &__limit{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #8886A0;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;

    @include respond-down(small) {
      width: 100%;
    }

    svg{
      margin-right: 10px;
    }

  }
  &__price{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  &__price-name{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    margin-right: 10px;
    color: #8886A0;

  }
  &__price-number{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: 0.05em;

    /* Черный */

    color: #000000;
  }

  &__low-wrap{
    display: flex;

    @include respond-down(small) {
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }

  &__date-wrap{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;

    @include respond-down(small) {
      margin-bottom: 30px;

      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

    }
  }
}