.production-safety-page {
  .block-index-wrapper.no-margin {
    margin: 0;
  }

  .index-block {
    &.hero {
      height: 550px;
      @include respond-down(middle) {
        height: 500px;
      }
      @include respond-down(small) {
        height: auto;
        padding-bottom: 30px;
      }

      .breadcrumbs {
        position: relative;
        z-index: 90;
        color: #ffffff;

        .breadcrumbs-block {
          margin-top: 30px;
        }

        .breadcrumbs-list {
          li {
            color: #ffffff;

            a {
              color: #ffffff;
            }
          }
        }
      }

      .hero__text {
        padding: 100px 0px 0px 0px;
        color: #fff;
        @include respond-down(small) {
          padding: 0px;
        }
      }

      .hero__title {
        @include respond-property(font-size, 25px, 30px, 30px, 30px, 32px, 35px);
        font-weight: 600;
        width: 100%;
        max-width: 850px;

        @include respond-down(large) {
          max-width: 730px;
        }

        &:after {
          display: none;
        }

        .yellow, span {
          color: $orange;
        }
      }

      .hero__bg {
        background-size: cover;
        background-position: 50% 50%;

        &::before {
          background-color: transparent;
        }
      }

      .hero__description {
        @include respond-property(font-size, 18px, 20px, 20px, 20px, 20px, 20px);
        margin: 35px 0px;
        max-width: 730px;

        .yellow, span {
          color: $orange;
        }

        @include respond-down(small) {
          text-align: left;
          line-height: 1.1;
        }

      }
    }
  }

  .safety {

    .block.title {
      margin-bottom: 50px;

      @include respond-down(small) {
        margin-bottom: 25px;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;

      @include respond-down(small) {
        flex-direction: column;
      }

      .pic {
        flex-basis: 43%;
        background-size: cover;

        @include respond-down(small) {
          flex-basis: auto;
          padding-top: 60%;
          margin-bottom: 30px;
        }
      }

      .docs {
        flex-basis: 54%;

        @include respond-down(small) {
          flex-basis: auto;
        }

        .doc {
          display: flex;
          justify-content: space-between;
          min-height: 112px;
          padding: 15px 22px;
          margin-bottom: 12px;
          border: 1px solid #979797;
          border-left: 6px solid #F5B042;

          @include respond-down(small) {
            min-height: 100px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .info {
            max-width: 540px;
            @include respond-down(middle) {
              max-width: 450px;
            }

            .name {
              display: block;
              font-size: 18px;
              color: #191A19;
              margin-bottom: 12px;

              @include respond-down(middle) {
                font-size: 16px;
              }

              @include respond-down(small) {
                font-size: 14px;
              }
            }

            .specific {
              display: block;
              color: #838383;
            }
          }

          .download {
            .icon {
              .ic_download_active {
                display: none;
              }
            }
          }

          &:hover {
            border-color: #F5B042;
            .info {
              .name {
                color: #001E4D;
                text-decoration: underline;
              }
            }

            .download {
              .icon {
                .ic_download_active {
                  display: block;
                }

                .ic_download {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &.production-safety {
      .content {
        .pic {
          @include respond-down(small) {
            order: 0;
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position-y: 70% ;
          }
        }
      }
    }

    &.industrial-safety {
      padding: 75px 0 60px;
      background-color: #ffffff;

      @include respond-down(small) {
        padding: 45px 0 30px;
      }

      .content {
        .pic {
          @include respond-down(small) {
            order: 0;
          }
        }

        .docs {
          @include respond-down(small) {
            order: 1;
          }
        }
      }
    }
  }
}