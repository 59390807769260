.not-liquid-catalog-menu{
  cursor: pointer;
  position: relative;

  &__select{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    cursor: pointer;
    position: relative;
  }

  &__name{
    background-color: #8886A0;
    color: #fff;
    padding: 19px;
    height: 55px;
    width: 100%;
    font-size: 16px;
  }
  &__arrow{
    background-color: #8886A0;
    height: 55px;
    padding: 20px;

    svg{
      filter: invert(1);
    }
  }

  &__dropdown{
    position: absolute;
    top: 55px;
    width: 100%;
    z-index: 1;
    border-top: 1px solid #fff;

    &.hide{
      display: none;
    }
  }

  &__category{
    border-bottom: 1px solid #fff;
    background: #8886A0;
    padding: 10px 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    height: 55px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    &.active{
      background-color: #616574;
    }

    &.main{
      background-color: #161245;
      font-weight: bold;
      padding: 10px 20px;
    }
  }
}