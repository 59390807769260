.repair-page {
  position: relative;
  z-index: 100;
  .index-block {
    &.hero {
      @include respond-down(middle){
        height: 550px;
      }
      @include respond-down(small){
        height: auto;
        padding-bottom: 30px;
      }
      .breadcrumbs {
        position: relative;
        z-index: 90;
        color: #ffffff;

        .breadcrumbs-block {
          margin-top: 30px;
        }
        .breadcrumbs-list {
          li {
            color: #ffffff;

            a {
              color: #ffffff;
            }
          }
        }
      }

      .hero__text {
        padding: 100px 0px 0px 0px;
        color: #fff;
        @include respond-down(small){
          padding: 0px;
        }
      }

      .hero__title {
        @include respond-property(font-size, 25px, 30px, 30px, 30px, 32px, 35px);
        font-weight: 600;
        width: 100%;
        max-width: 850px;

        @include respond-down(large) {
          max-width: 730px;
        }

        .yellow {
          color: $orange;
        }
      }

      .hero__description {
        @include respond-property(font-size, 18px, 20px, 20px, 20px, 20px, 20px);
        margin: 35px 0px;
        max-width: 547px;

        @include respond-down(small){
          text-align: left;
          line-height: 1.1;
        }

      }
    }
  }

  .second-block {
    .tech-wrapper {
      display: flex;
      flex-wrap: nowrap;

      @include respond-down(small){
        display: block;
      }

      .left-icon {
        flex: 1 1 456px;
        text-align: center;
        img{
          width: 250px;

          @include respond-down(small){
            width: 125px;
          }
        }
      }

      .right-tech-list {
        flex: 1 1 auto;

        @include respond-down(small){
          margin-top: 30px;
        }

        .item-wrapper {
          position: relative;
          &:hover {

            .item {
              span {
                border-color: $orange;
              }
            }
          }

          .item {
            cursor: pointer;
            margin-bottom: 20px;
            white-space: nowrap;


            span {
              @include respond-property(font-size, 14px, 14px, 14px, 16px, 18px, 18px);
              border-bottom: 1px dashed transparent;
              font-weight: bold;
              display: inline-block;
              margin: 0 5px;
              vertical-align: top;
              @include respond-down(small){
                white-space: normal;
                max-width: calc(100% - 70px);
                border-bottom: none;
              }
            }
          }
          .item-description {
            transition: max-height 0.4s;
            opacity: 0;
            padding: 10px 15px;
            background: #ffffff;
            border-radius: 10px;
            margin: 10px 0px;
            display: none;

            &.active {
              opacity: 1;
              display: block;
            }
          }

        }
      }
    }
  }

  h2 {
    @include respond-property(font-size, 20px, 20px, 25px, 28px, 27px, 30px);
    @include respond-property(line-height, 20px, 20px, 25px, 28px, 27px, 37px);
    @include respond-property(margin-bottom, 5px, 5px, 10px, 10px, 10px, 13px);
    font-weight: 600;
    text-align: center;
  }

  .article {
    @include respond-property(font-size, 12px, 12px, 14px, 16px, 16px, 16px);
    text-align: center;
  }

  .process-block {

    .process-wrapper {

      position: relative;
      &:before{
        top: 60px;
        position: absolute;
        bottom: 100px;
        content: '';
        width: 2px;
        background: #ACB4C0;
        left: 50%;
        transform: translateX(-50%);

        @include respond-down(small){
          transform: none;
          left: 0px;
        }
      }


      .end-wrapper{
        background: #EBEDE9;
        text-align: center;
        max-width: 505px;
        margin: 30px auto 0px auto;
        font-weight: bold;
        font-size: 20px;

        .yellow{
          color: $orange;
        }
      }
      .item-row {
        display: flex;
        justify-content: flex-start;

        @include respond-down(small){
          display: block;
          margin-bottom: 20px;
        }
        .item-main {
          max-width: calc(50% - 94px / 2);
          position: relative;
          padding-right: 40px;

          @include respond-down(small){
            padding-right: 0px;
            padding-left: 40px;
            width: 100%;
            max-width: 100%;
          }
          &:after {
            transform: translateX(100%) translateY(-50%);
            position: absolute;
            right: 42px;
            width: 37px;
            height: 76px;
            background: transparent url("../svg/angle.svg") 50% 50% no-repeat;
            content: '';
            top: 50%;

            @include respond-down(small){
              transform: translateX(-100%) translateY(-50%) rotate(180deg);
              left: 62px;
              right: auto;
              background: transparent url("../svg/angle.svg") 50% 50% no-repeat;
            }
          }

          &:before{
            width: 12px;
            height: 12px;
            background: #F5A623;
            box-shadow: 0 2px 4px 0 rgba(246,176,39,0.67);
            position: absolute;
            right: -47px;
            content: '';
            border-radius: 12px;
            transform: translateY(-50%) translateX(50%);
            top: 50%;

            @include respond-down(small){
              transform: translateY(-50%) translateX(-50%);
              left: 0px;
            }

          }

          .item-wrapper {
            background: #fff;
            padding: 44px 45px 46px 16px;
            display: flex;
            @include respond-down(small){
              padding: 20px 23px;
            }
            .item-number {
              font-weight: 900;
              font-size: 45px;
              color: #BFBFBF;
              @include respond-down(small){
                font-size: 25px;
              }
            }
            .name-dsc {
              margin-left: 30px;
              @include respond-down(small){
                margin-left: 20px;

              }
              .item {
                @include respond-property(font-size, 14px, 14px, 18px, 20px, 20px, 20px);
                @include respond-property(margin-bottom, 5px, 5px, 10px, 10px, 10px, 13px);
                font-weight: bold;
              }
              .item-description {
                @include respond-property(font-size, 11px, 11px, 14px, 14px, 16px, 16px);

              }
            }
          }
        }
        &:nth-child(2n) {
          justify-content: flex-end;


          .item-main{
            padding-right: 0px;
            padding-left: 40px;
            &:after {
              transform: translateX(-100%) translateY(-50%) rotate(180deg);
              left: 42px;
              right: auto;
              background: transparent url("../svg/angle.svg") 50% 50% no-repeat;

              @include respond-down(small){
                left: 62px;
              }
            }

            &:before{
              right: 0px;
              left: -47px;
              transform: translateY(-50%) translateX(-50%);

              @include respond-down(small){
                left: 0px;
              }
            }
          }

        }
      }
    }
  }

  .email-phone {

    @include respond-down(small) {
      margin: 0 0 20px;
    }
  }

  .index-block.consult .consult__button {
    @include respond-down(small) {
      margin: 0;
    }
  }
}
