.not-liquid-price{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;

  &__text{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;

    /* Св. Синий */

    color: #8886A0;

  }
  &__price{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    letter-spacing: 0.05em;

    /* Черный */

    color: #000000;
  }
  &__ruble{

  }
}