.gallery {
    // .gallery__btn-box
    &__btn-box {
        display: flex;
        justify-content: space-around;
        margin-bottom: 35px;
        border-bottom: 2px solid #fff;
        // @media(min-width:768px) {
            margin-bottom: 55px;
        // }
    }
    // .gallery__btn-name
    &__btn-name {
        // margin-right: 140px;
        margin-bottom: -2px;
        padding-bottom: 11px;
        border-bottom: 5px solid transparent;
        border-radius: 0px;
        font-family: $proximaNova;
        font-size: 18px;
        line-height: calc(23 / 18 * 100%);
        color: $blue;
        background: transparent;
        text-transform: uppercase;
        &_active {
            color: #F5B142;
            border-bottom: 5px solid #F5B142;
        }
        // &:last-child {
        //     margin-right: 0;
        // }
        @media(min-width:768px) {
            font-size: 27px;
            line-height: calc(34 / 27 * 100%);
        }
    }
    // .gallery__tab-box
    &__tab-box {
    }
    // .gallery__tab
    &__tab {
        display: none;
        &_active {
            display: block;
        }
    }
    // .gallery__row
    &__row {
        margin-bottom: 65px;
        &_photo {
            @media(min-width:900px) {
                display: flex;
            }
        }
        &_video {
            display: flex;
            flex-wrap: wrap;
            @media(min-width:768px) {
                margin: 0 -10px;
                overflow: hidden;
            }
            @media(min-width:1200px) {
                margin: 0 -20px;
            }
        }
    }
    // .gallery__category
    &__category{
        @media(max-width:899px) {
            position: sticky;
            z-index: 2;
            top: 75px;
            // margin-left: -20px;
            // margin-right: -20px;
            border-top: 10px solid #ecedec;
            margin-bottom: 30px;
        }
        @media(min-width:900px) {
            position: relative;
            flex: 0 0 300px;
            margin-right: 20px;
        }
    }
    // .gallery__current-btn
    &__current-btn {
        @extend .new-btn;
        display: none;
        @media(max-width:899px) {
            display: flex;
            justify-content: space-between;
            background: #F5B142;
            &::after {
                content: '';
                background: no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0Ljk5OSAybC02LjUgNi41TTguNSA4LjVMMiAyIiBzdHJva2U9IiMyRjM0MzUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PC9zdmc+);
                flex: 0 0 19px;
                height: 10px;
                transition: all 0.3s ease-in;
            }
            &_active {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
    // .gallery__btn-body
    &__btn-body {
        @media(max-width:899px) {
            display: none;
            &_active {
                display: block;
            }
        }
        @media(min-width:900px) {
            position: sticky;
            top: 100px;
            display: block;
        }
    }
    // .gallery__btn
    &__btn {
        @extend .new-btn;
        color: #000;
        @media(max-width:899px) {
            background: #fff;
            border-bottom: 2px solid #F5B142;
        }
        @media(min-width:900px) {
            transition: all 0.3s ease-in;
            background: transparent;
            &_active {
                background: #F5B142;
            }
            &:hover {
                background: #fff;
            }
        }
    }
    // .gallery__tab-body
    &__tab-body {
        // @media(max-width:899px) {
            display: none;
            &_active {
                display: block;
                @media(min-width:900px) {
                    flex: 1 1 auto;
                }
            }
        // }
    }
    // .gallery__photo-row
    &__photo-row {
        position: relative;
    }
    // .gallery__photo-item
    &__photo-item {
        width: 50%;
        a {
            display: flex;
            width: 100%;
            height: 100%;
            transition: all 0.4s;
            padding: 10px;
            img {
                flex: 1 1 auto;
            }
        }
        @media(min-width:1260px) {
            width: calc(100% / 3);
        }
    }
    //.gallery__video-column
    &__video-column {
        flex: 1 1 100%;
        margin-bottom: 30px;

        @media(min-width:768px) {
            flex: 0 1 calc(100% / 2);
            padding: 0 10px;
        }
        @media(min-width:1200px) {
            flex: 0 1 calc(100% / 3);
            padding: 0 20px;
        }
    }
    // .gallery__video-item
    &__video-item {
        position: relative;
        margin-bottom: 15px;
        // like 16 / 9 aspect-ratio
        padding-bottom: 56.25%;
        display: flex;
        justify-content: center;

        @include respond-up(middle){
            aspect-ratio: 16/9;
            margin: 0px auto;
            padding-bottom: 0;
        }

    }
    // .gallery__video-poster
    &__video-poster, &__video-poster img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 2;
        cursor: pointer;
        &:hover {
            .gallery__video-poster-btn {
                color:rgba(0, 0, 0, 0.25)
            }

        }
    }
    // .gallery__video-poster-btn
    &__video-poster-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 35px;
        background: transparent;
        z-index: 3;
        color: #f00;
        transition: color 0.3s ease;
        .svg-icon {
            width: 100%;
            height: 100%;
        }
        @media(min-width:768px) {
            width: 80px;
            height: 55px;
        }
    }
    // .gallery__video
    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    // .gallery__video-desc
    &__video-desc {
        font-family: $proximaNova;
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 14px;
        line-height: calc(17 / 14 * 100%);
        color: #000;
    }
}
