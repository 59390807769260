.search-bar{

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__form{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    input[type="text"]{
      height: 55px;
      color: #616574;
      background-color: #fff;
      border-radius: 0px;
      padding: 10px 20px;
    }
  }

  &__button{
    background-color: #fff;
    border-radius: 0px;
    padding: 18px 20px;
    height: 55px;
  }

  &__input{
    height: 55px;
    padding: 0px;
  }
}