.top-block {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @include respond-down(medium) {
    height: 300px;
    img {
      object-fit: cover;
      height: 100%;
    }
  }

  @include respond-down(small) {
    margin: 0 -20px;
    height: 200px;
  }

  .description {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0px 20px 0px 43px;


    @include respond-down(medium) {
      padding: 0px 20px 0px 20px;
    }

    @include respond-down(small) {
      padding: 0px 10px 0px 10px;
    }

    h1, .text {
      color: #fff;
    }

    h1.lower.center {
      text-align: center;
      text-transform: none;
      font-weight: bold;
    }

    h1 {
      max-width: 650px;
      text-transform: uppercase;
      font-size: 26px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 50px;
      @include respond-down(medium) {
        max-width: 100%;
        margin-bottom: 30px;
        line-height: 1.3;
        font-size: 23px;
      }
      @include respond-down(small) {
        font-size: 20px;
        margin-bottom: 20px;
        text-transform: none;
        line-height: 1.1;
      }

      span {
        color: $orange;
      }
    }

    &.text-center{
      h1{
        text-align: center;
        margin: 0 auto;
      }
    }

    .text {
      max-width: 830px;
      font-size: 20px;
      @include respond-down(medium) {
        font-size: 14px;
      }

      @include respond-down(small) {
        font-size: 12px;
      }

      span {
        color: $orange;
        font-weight: bold;
      }
    }
  }
}
