.photo-gallery-page {
  .categories-list {
    .product-card__item {
      background-size: cover;
      padding-top: 0px;

      .product-card__title {
        margin: 0 -18px;
        line-height: 1.2;
        padding: 6px 4px;
        position: relative;
        background: rgba(255, 255, 255, 0.5);
        span {
          position: relative;
          z-index: 2;
        }
      }
    }
  }

  .gallery-holder {
    margin: 0 -10px;
    @include respond-down(small){
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 18px;
    }
    .gallery-item {
      width: 25%;
      overflow: hidden;
      @include respond-down(middle) {
        width: 33.3333%;
      }
      @include respond-down(medium) {
        width: 50%;
      }
      @include respond-down(small) {
        width: auto;
        &.vertical{
          grid-row: span 2;
        }
      }

      a {
        display: flex;
        width: 100%;
        height: 100%;
        transition: all 0.4s;
        padding: 10px;

        @include respond-down(small){
          display: block;
          height: auto;
          padding: 0px;
        }


        &:hover{
          transform: scale(1.05, 1.05);
        }

        img {
          object-fit: cover;
          image-rendering: -moz-crisp-edges;
          image-rendering: -moz-crisp-edges;
          image-rendering: -o-crisp-edges;
          image-rendering: -webkit-optimize-contrast;
          -ms-interpolation-mode: nearest-neighbor;
        }
      }

      .video-wrapper {
        position: relative;

        .play-button {
          display: block;
          width: 80px;
          height: 80px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -40px;
          margin-top: -40px;
          cursor: pointer;
          border-radius: 50%;
          transform: scale(1);
          transition: all ease-out .2s;
        }
      }
    }
  }
}

.gallery-sort {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  overflow: hidden;
  // .gallery-sort__btn
  &__btn {
    flex: 1 1 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 0 15px;
    margin: 0 10px 15px;
    border: 1px solid $orange;
    border-radius: 30px;
    font-family: $font;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: $black;
    text-align: center;
    transition: $default-transition;
    &_active {
      background: $orange;
    }
    &:hover {
      color: $white;
      border: 1px solid $blue2;
      background: $blue2;
    }
  }
}
