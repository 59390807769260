.services-page {
  & > .title {
    margin-bottom: 15px;

    .page-title {
      font-weight: 500;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      color: #000;
    }
  }

  .paragraph {
    p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 26px;

      @include respond-down(small) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .list {
    margin-bottom: 80px;

    @include respond-down(small){
      margin-bottom: 40px;
    }

    &__item:not(:last-child) {
      margin-bottom: 22px;
    }

    .service {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding: 50px 50px 45px;
      background-color: #ffffff;

      @include respond-down(medium) {
        flex-direction: column;
        justify-content: flex-start;
      }

      @include respond-down(small) {
        padding: 25px 25px 20px;
      }

      &__title {
        font-size: 24px;
        font-weight: bold;
        width: 100%;
        margin-bottom: 8px;

        @include respond-down(medium) {
          margin-bottom: 50px;
          text-align: center;
        }
      }

      &__pic {
        width: 356px;
        text-align: center;

        @include respond-down(medium) {
          width: 100%;
          margin-bottom: 50px;
        }

        .icon {
          @include respond-down(medium) {
            padding: 0 !important;
          }
        }

        .svg-icon.magnet-old-to-new {
          @include respond-down(small) {
            width: 100%;
            max-width: 356px;
            height: 0;
            padding-bottom: 42.7%;
            background-size: contain;
          }
        }

        .svg-icon.magnet-repair-white {
          @include respond-down(small) {
            width: 80%;
            max-width: 161px;
            height: 0;
            padding-bottom: 42.7%;
            background-size: contain;
          }
        }
      }

      &__description {
        width: calc(100% - 426px);

        @include respond-down(medium) {
          width: 100%;
        }

        & > div {
          @include respond-down(medium) {
            text-align: center;
          }
        }
      }

      &__button {
        height: 52px;
        line-height: 50px;
        font-weight: 500;
        font-size: 16px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }


  }

}
