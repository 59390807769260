.replacement-page {
  @import "parts/top";
  @import "parts/top_new";
  h2 {
    @include respond-property(margin-bottom, 30px, 40px, 50px, 75px, 75px, 75px);

    &.left-after {
      &:after {
        left: 0;
        transform: none;
      }
    }

  }


  .variants {
    margin-top: 50px;

    .variant {
      display: flex;
      justify-content: space-between;
      @include respond(small) {
        flex-wrap: wrap;
      }

      .icon-block {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 200px;
        flex: 0 0 200px;


        @include respond(small) {
          flex: 1 1 50%;
          height: auto;

          &.left-block {
            align-items: flex-start;
          }
          &.right-block {
            align-items: flex-end;
          }
          .svg-icon, .image-icon {
            width: 82px;
            height: 86px;
            background-size: cover;
            align-items: flex-start;
          }
        }

        a {
          white-space: nowrap;
          .title-holder{
            border-bottom: 1px solid;
            color: black;
            transition: $default-transition;

            &:hover {
              border-bottom-color: transparent;
            }
          }

        }

        .title {
          display: flex;
          align-items: center;
          height: 35px;
          font-size: 15px;
          white-space: nowrap;

          @include respond(small) {
            display: none;
          }

          .counter {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #D8D8D8;
            text-align: center;
            line-height: 35px;
            font-weight: bold;
            margin-right: 10px;


          }
        }
      }

      .center-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        @include respond(small) {
          order: 2;
          flex: 0 0 100%;
          margin-top: 30px;
        }

        .arrow-holder {
          position: absolute;
          top: 10px;
          overflow: hidden;
          margin: 0 20px;
          left: 0;
          right: 0;

          @include respond(small) {
            top: -80px;
            margin: 0 100px;
          }

          .arrow-line {
            max-width: 100%;
            background-position: 100% 100%;
          }
        }

        .text {
          max-width: 600px;
          text-align: center;
          margin: 0 auto;
          font-size: 18px;
          line-height: 25px;

          @include respond(small) {
            font-size: 14px;
            p {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  .or {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
    height: 135px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(small) {
      margin: 30px 0px;
    }

    &:before, &:after {
      content: '';
      width: 35px;
      height: 1px;
      background: #979797;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }

    &:before {
      top: calc(35px / 2);
    }

    &:after {
      bottom: calc(35px / 2);
    }
  }


  .what-get-block {
    @include respond-property(margin-top, 30px, 30px, 40px, 50px, 80px, 80px);
    background: #FFFFFF;
    background-size: cover;
    background-position: 50% 50%;

    @include respond(small) {
      padding: 10px 15px;
      flex-wrap: wrap;
    }

    .left {
      padding: 50px 15px 50px 45px;
      background-image: linear-gradient(122deg, #FFFFFF 10%, rgba(255,255,255,0.00) 100%);

      @include respond(small) {
        width: 100%;
        flex: 1 1 100%;
        padding: 20px;
        background: #fff  ;
      }
    }

    h2 {
      @include respond-property(margin-bottom, 20px, 20px, 30px, 30px, 30px, 40px);
      justify-content: flex-start;
      @media(min-width:784px) {
        &::after {
          content: none;
        }
      }
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      max-width: 500px;

      p {
        font-size: 14px;
        line-height: 1.3;
        span.orange{
          color: $orange;
        }
      }

      .value {
        margin-left: 20px;
        @include respond-down(small){
          h5{
            font-size: 12px;
            display: inline;
          }
          h6{
            font-size: 13px;
            font-weight: normal;
            display: inline;
          }
        }
      }

      .icon {
        flex: 0 0 auto;
      }
      .icon-bullet{
        width: 11px;
        height: 11px;
        border-radius: 50%;
        border: 2px solid $orange;
        flex: 0 0 auto;
      }
    }

  }


  .magnet-block {
    @include respond-property(margin-top, 30px, 30px, 40px, 50px, 80px, 80px);

    h2 {
      @include respond-property(margin-bottom, 10px, 10px, 10px, 10px, 10px, 10px);
    }

    .description {
      @include respond-property(font-size, 12px, 14px, 14px, 20px, 20px);
    }

    .magnet-holder {
      display: flex;
      position: relative;
      margin: 50px auto;
      background: #FFFFFF;
      padding: 30px;

      .magnet{
        max-width: 902px;
        margin: 0 auto;
      }

      .annotations {
        margin-left: 155px;
        margin-top: 20px;
        position: relative;

        .line {
          position: absolute;
          left: -340px;
          right: 340px;
          height: 2px;
          top: 0;
          transform: translateY(-50%);
          background: transparent url("../images/Replacement/bordered-line.svg") 0 0 repeat-x;
          z-index: 0;

          &:before {
            width: 11px;
            height: 11px;
            content: '';
            background: $orange;
            border-radius: 50%;
            border: 2px solid $blue;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }

          &:after {
            z-index: 1;
            width: 11px;
            height: 11px;
            content: '';
            background: $orange;
            border-radius: 50%;
            border: 2px solid $blue;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &.first {
            transform: rotate(8deg);
            top: 30px;

            & + .item {
              margin-top: 20px;
            }
          }

          &.second {

          }

        }

        .item {
          background: #FFFFFF;
          border: 2px solid $orange;
          border-radius: 12px;
          font-size: 19px;
          font-weight: bold;
          padding: 20px 27px;
          margin-bottom: 50px;
          position: relative;
          width: 340px;


        }
      }

    }
  }


  .description-program{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond-property(margin-top, 15px, 25px, 25px, 30px, 30px, 30px);
    .left-holder{
      flex: 0 1 450px;
      @include respond-down(medium){
        zoom: 0.7;
      }
    }
    .right-holder{
      flex: 1 1 auto;
      h2{
        @include respond-property(margin-bottom, 20px, 25px, 30px, 35px, 40px, 40px);

      }
      .description{
        max-width: 605px;
        font-size: 20px;
        line-height: 1.4;
        @include respond-down(medium) {
          font-size: 14px;
        }

        @include respond-down(small) {
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .text{
            max-width: 200px;
            padding-left: 20px;
          }
          .icon-mobile{
            zoom: 0.3;
          }
        }

        span {
          color: $orange;
          font-weight: bold;
        }
      }

    }
  }

  .list-whats-start-holder{
    margin-bottom: 10px;
    ul{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      @include respond-down(small){
        margin: 0px;
      }
      .item-what-holder {
        width: 50%;
        padding: 10px;

        &:first-child,
        &:nth-child(2) {
          .info-holder {
            .description {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
          }
        }

        &.smalled{
          width: 33.3333%;
          @include respond-down(small){
            width: 100%;
            padding: 0px 0px 10px 0px;
          }

          .wrapper-item{
            .info-holder{
              justify-content: space-between;
              flex: 1 0 auto;

              .icon{
                width: 125px;
                flex: 0 0 125px;
              }
              .description{
                flex: 1 1 auto;
              }
            }
          }

        }
        @include respond-down(small){
          width: 100%;
          padding: 0px 0px 10px 0px;
        }

        .wrapper-item{
          background: #ffffff;
          padding: 45px 20px 15px 0px;
          height: 100%;
          flex-direction: column;
          display: flex;
          justify-content: space-between;
          @include respond(small){
            padding: 20px 20px 15px 0px;

          }

          .number{
            font-size: 30px;
            color: $blue;
            font-weight: bold;
            text-align: right;
            &:after{
              content: '.';
            }
            @include respond(small){
              order: -1;
              text-align: left;
              padding-left: 20px;
            }
          }
          .info-holder{
            display: flex;
            font-size: 18px;
            align-items: center;
            .description{
              max-width: 380px;
            }
            .icon{
              width: 190px;
              text-align: center;
              flex: 0 0 190px;
              @include respond-down(medium){
                flex: 0 0 120px;
                width: auto;
              }
            }
          }
          .buttons-holder{
            margin-top: 20px;
            .button{
              padding: 14px 0px;
              width: 150px;
              text-transform: none;
              font-weight: normal;
              @include respond-down(medium){
                padding: 10px 0px;
              }
            }
          }
        }
      }
    }
  }

  .consult{
    margin: 40px 0px;

    @include respond-down(small){
      padding-bottom: 30px;
    }
  }

  .index-block.consult .consult__button {
    @include respond-down(small) {
      margin: 0;
    }
  }


  &.eng{
    .description-program{
      justify-content: center;

      @include respond-up(middle){
        .left-holder{
          flex: none;
        }
        .right-holder{
          flex: none;
          margin-left: 50px;
        }
      }

    }
    @include respond-down(small){
      .description-program{
        .right-holder{
          .description{
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.modal-about-holder {
  max-width: 500px;
  font-size: 17px;
  text-align: left;
  line-height: 1.5;

  .image {
    height: 230px;
    display: flex;
    margin-bottom: 20px;

    img {
      object-fit: cover;
    }
  }
}


