.index-block {
  &.hero {
    @include respond-down(middle){
      height: 550px;
    }
    @include respond-down(small){
      height: auto;
      padding-bottom: 30px;
    }
    .breadcrumbs {
      position: relative;
      z-index: 90;
      color: #ffffff;

      .breadcrumbs-block {
        margin-top: 30px;
      }
      .breadcrumbs-list {
        li {
          color: #ffffff;

          a {
            color: #ffffff;
          }
        }
      }
    }

    .hero__text {
      padding: 100px 0px 0px 0px;
      color: #fff;
      @include respond-down(small){
        padding: 0px;
      }
    }

    .hero__title {
      @include respond-property(font-size, 25px, 30px, 30px, 30px, 32px, 35px);
      font-weight: 600;
      width: 100%;
      max-width: 850px;

      @include respond-down(large) {
        max-width: 730px;
      }

      &:after{
        display: none;
      }

      .yellow, span {
        color: $orange;
      }
    }

    .hero__bg{
      background-size: cover;
      background-position: 50% 50%;
    }

    .hero__description {
      @include respond-property(font-size, 18px, 20px, 20px, 20px, 20px, 20px);
      margin: 35px 0px;
      max-width: 830px;

      .yellow, span {
        color: $orange;
      }

      @include respond-down(small){
        text-align: left;
        line-height: 1.1;
      }

    }
  }
}
